<template>
    <div class="roleTab roleTab-lh">
      <el-form ref="queryList" :model="queryList" label-width="120px">
        <el-form-item label="选择询价单位">
          <el-checkbox-group v-model="queryList.checkBoxList">
            <div class="listrow" :key="index" v-for="(item,index) in queryList.checkSupplier">
              <el-checkbox :label="item.label"></el-checkbox>
              <el-select
              v-if="queryList.checkBoxList.indexOf(item.label)>=0"
                v-model="queryList.wuLiao[index]"
                multiple
                placeholder="请选择物料">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="queryList.bz" placeholder="请填写备注"></el-input>
        </el-form-item>
        <el-form-item class="tbSap" label="是否同步SAP">
          <el-radio-group v-model="queryList.sap">
            <el-radio label="1">是</el-radio>
            <el-radio label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'SecondInquiry',
  props: ['formData', 'listId'],
  data () {
    return {
      queryList: {
        checkBoxList: [],
        checkSupplier: [],
        wuLiao: [],
        bz: '',
        sap: ''
      },
      options: []
    }
  },
  created () {
    this.getSubblier()
  },
  mounted () {},
  methods: {
    // 根据ID请求数据
    getSubblier () {
      const id = this.listId
      if (id) {
        request('/api/inquiry/getMessageByPurchasing/' + id, 'get').then((res) => {
          if (res.code === '200') {
            var index = 0
            for (let i = 0; i < res.data.inquirySupplierList.length; i++) {
              if (res.data.inquirySupplierList[i].isAccept === 2) {
                index = i
                res.data.inquirySupplierList.splice(index, 1)
                i = i - 1
              }
            }

            res.data.inquirySupplierList.forEach(item => {
              this.queryList.checkSupplier.push({ label: item.name, value: item.code })
            })
            res.data.inquiryMaterialMap.data.forEach(item => {
              this.options.push({ label: item[res.data.inquiryMaterialMap.title[0]], value: item[res.data.inquiryMaterialMap.title[0]] })
            })
          }
        })
      }
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
  .roleTab{
    .listrow{
            display: flex;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
      .el-checkbox{
        // margin-bottom: 10px;
        margin-top: 10px;
      }
      .el-select{
        margin-left: 10px;
        width: 77%;
      }
    }
    :deep(.el-form-item__content){
      line-height: normal;
    }
    :deep(.el-form-item__label){
      line-height: normal;
      margin-top: 10px;
    }
    .tbSap{
      :deep(.el-form-item__label){
        margin-top: 0;
      }
    }
  }
</style>
